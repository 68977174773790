import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TopHeroComponent from "../components/herocomponents/topherocomponent"
import R_BLUE_BG from "../images/background-images/bg-royal-blue.svg"
import { colors } from "../utils/colors"
import InPageFAQ from "../components/faq-components/inpagefaq"
import ContactForm from "../components/bodycomponents/contact-form"
import {
  sellEquipmentFaqItems,
  buyEquipmentFaqItems,
  auctionsFaqItems,
  refurbishedFaqItems,
  newArrivalsFaqItems,
  logisticsFaqItems,
  appraisalFaqItems,
  relocationFaqItems,
} from "../utils/data/faq"

import SELL_ICON from "../images/icons/SSLLC Icon_Services_Sell Equipment.svg"
import BUY_ICON from "../images/icons/SSLLC-Icon_Services_Buy-Equipment-2.svg"
import AUCTION_ICON from "../images/icons/SSLLC Icon_Services_Auctions.svg"
import REFURBISHED_ICON from "../images/icons/SSLLC_Icon_Refurbished.svg"
import NEW_ARRIVALS_ICON from "../images/icons/SSLLC_Icon_New Arrivals.svg"
import LOGISTICS_ICON from "../images/icons/SSLLC Icon_Services_Logistics 2.svg"
import APPRAISALS_ICON from "../images/icons/SSLLC Icon_Services_Appraisal.svg"
import RELOCATION_ICON from "../images/icons/SSLLC Icon_Services_Relocation.svg"

export default function FAQPage() {
  function scrollToSection(id) {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <>
      <Layout>
        <SEO
          title={`Surplus Solutions FAQs | Your Questions Answered`}
          description={`Find answers to common questions about Surplus Solutions' services, including appraisals, equipment sales, logistics, and relocation.`}
        />
        <TopHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          fontShadowColor={colors.royalBlue}
          shadowColor="shadow-royalBlue-bottom"
          pageTitle="Surplus Solutions FAQ"
          title={
            <>
              Everything You Need to Know
              <br />
              About Our Services
            </>
          }
          body={
            <p className="pt-4 text-balance">
              Whether it’s buying equipment, bidding at auctions, or relocating
              a lab, we can answer all your questions.
            </p>
          }
        />
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl py-14">
            <h2 className="text-center mb-0">Browse Our FAQs</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-14 gap-y-20 md:gap-y-16 mt-9 items-center justify-items-center text-center">
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("sell-equipment")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={SELL_ICON}
                    alt="A cartoon image of a cardboard box being handed over in exchange for currency"
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Sell Equipment</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("buy-equipment")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={BUY_ICON}
                    alt="A cartoon image of a hand reaching out to take a package with a price tag, symbolizing a purchase or transaction."
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Buy Equipment</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("auctions")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={AUCTION_ICON}
                    alt="A cartoon image of a judge's gavel"
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Auctions</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("refurbished-equipment")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={REFURBISHED_ICON}
                    alt="A cartoon image of green shield with arrows behind it"
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Refurbished Equipment</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("new-arrivals")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={NEW_ARRIVALS_ICON}
                    alt={`A cartoon image of a teal badge reading "NEW"`}
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">New Arrivals</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("logistic-services")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={LOGISTICS_ICON}
                    alt="A cartoon image of a hand truck carrying a yellow package, symbolizing delivery or logistics."
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Logistic Services</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("appraisal-services")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={APPRAISALS_ICON}
                    alt="A cartoon image of a clipboard being examined by a magnifying glass with a dollar sign in the lens"
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Appraisal Services</h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div
                  onClick={() => scrollToSection("relocation-services")}
                  className="cursor-pointer"
                >
                  <img
                    className="mx-auto"
                    src={RELOCATION_ICON}
                    alt="A cartoon image of a shipping truck"
                    width={150}
                    height={150}
                  />
                  <h4 className="text-charcoal">Relocation Services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div className="shadow-custom-in bg-light-gray">
          <div className="container max-w-screen-xl">
            <div className="text-center pt-14 pb-8" id="sell-equipment">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={SELL_ICON}
                  alt="A cartoon image of a cardboard box being handed over in exchange for currency"
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>Sell Equipment FAQs</h2>
              </div>
              <InPageFAQ
                items={sellEquipmentFaqItems}
                shadowColor="shadow-red-top"
              />
            </div>
            <div className="text-center pt-14 pb-8" id="buy-equipment">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={BUY_ICON}
                  alt="A cartoon image of a hand reaching out to take a package with a price tag, symbolizing a purchase or transaction."
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>Buy Equipment FAQs</h2>
              </div>
              <InPageFAQ
                items={buyEquipmentFaqItems}
                shadowColor="shadow-royalBlue-top"
              />
            </div>
            <div className="text-center pt-14 pb-8" id="auctions">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={AUCTION_ICON}
                  alt="A cartoon image of a judge's gavel"
                  width={100}
                  height={100}
                />
                <h2>Auction FAQs</h2>
              </div>

              <InPageFAQ
                items={auctionsFaqItems}
                shadowColor="shadow-red-top"
              />
            </div>
            <div className="text-center pt-14 pb-8" id="refurbished-equipment">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={REFURBISHED_ICON}
                  alt="A cartoon image of green shield with arrows behind it"
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>Refurbished Equipment FAQs</h2>
              </div>
              <InPageFAQ
                items={refurbishedFaqItems}
                shadowColor="shadow-lime-top"
              />
            </div>
            <div className="text-center pt-14 pb-8" id="new-arrivals">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={NEW_ARRIVALS_ICON}
                  alt={`A cartoon image of a teal badge reading "NEW"`}
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>New Arrivals FAQs</h2>
              </div>
              <InPageFAQ
                items={newArrivalsFaqItems}
                shadowColor="shadow-turquoise-top"
              />
            </div>
            <div className="text-center pt-14 pb-8" id="logistic-services">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={LOGISTICS_ICON}
                  alt="A cartoon image of a hand truck carrying a yellow package, symbolizing delivery or logistics."
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>Logistic Services FAQs</h2>
              </div>
              <InPageFAQ
                items={logisticsFaqItems}
                shadowColor="shadow-goldenYellow-top"
              />
            </div>
            <div className="text-center pt-14 pb-8 " id="appraisal-services">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={APPRAISALS_ICON}
                  alt="A cartoon image of a clipboard being examined by a magnifying glass with a dollar sign in the lens"
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>Appraisal Services FAQs</h2>
              </div>
              <InPageFAQ
                items={appraisalFaqItems}
                shadowColor="shadow-orange-top"
              />
            </div>
            <div className="text-center pt-14 pb-8" id="relocation-services">
              <div className="flex flex-row items-center justify-center">
                <img
                  src={RELOCATION_ICON}
                  alt="A cartoon image of a shipping truck"
                  width={90}
                  height={90}
                  className="mr-4"
                />
                <h2>Relocation Services FAQs</h2>
              </div>
              <InPageFAQ
                items={relocationFaqItems}
                shadowColor="shadow-royalPurple-top"
              />
            </div>
          </div>
        </div>
        <ContactForm
          title="Still have questions?"
          body={
            <p>
              Our expert team is here to help! If you didn’t find the answer
              you’re looking for, don’t hesitate to contact us.
            </p>
          }
          backgroundColor={colors.containerGray}
          fontShadowColor={colors.royalBlue}
          shadow="shadow-royalBlue-bottom"
          formType="Contact"
        />
      </Layout>
    </>
  )
}
